body {
  margin: 0;
  background: #543093 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
label,
span {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}
